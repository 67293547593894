<template>
    <div class="coach-own-member-coupon iphonex-pb iphonex-pt">
        <headeBar left-arrow @click-left="newAppBack" :placeholder="false" onlyBack background="#ffffff00" >
            <template slot="left">
                <van-icon name="cross left_icon" :color="hasData&&!success?'#fff':'#000'" />
            </template>
        </headeBar>
        <template v-if="voucherInfo.teacherOwnUserId">
            <div v-if="success" class="success-box zv">
                <div class="s1">
                    <img alt class="suc-logo"
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach-own-member/coupon/success.png" />
                    优惠券领取成功!
                </div>
                <div class="s2">请至超鹿运动App或小程序"我的>优惠券"中查看使用。</div>
                <div class="s3">
                    <img alt
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach-own-member/coupon/my.png" />
                </div>
                <div class="s4">
                    <div class="remain" v-show="remain">
                        <img alt
                            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach-own-member/coupon/voucher.png"
                            class="v-logo" />
                        <div>还有 <span class="remain-num">{{ remain }}</span> 份可领取</div>
                    </div>
                    <div class="get">
                        <div class="sub" @click="next">{{ remain ? '继续领取' : '查看优惠券' }}</div>
                    </div>
                </div>
            </div>
            <div v-else class="zv" >
                <img alt
                    src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach-own-member/coupon/bg.png" />
                <div class="coupon-body">
                    <div class="coupon-name">{{ voucherInfo.voucherName }} x{{ voucherInfo.voucherNumber || 0 }} 张
                    </div>
                    <div class="coupon-rule">
                        <div>领券规则：</div>
                        <div>{{ voucherInfo.voucherDescription }}</div>
                    </div>
                </div>
                <div class="agree">
                    <div class="l1">
                        <div class="ck" @click="checked = !checked">
                            <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach-own-member/coupon/checked.png"
                                v-show="checked" />
                            <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach-own-member/coupon/nochecked.png"
                                v-show="!checked" />
                        </div>
                        我已阅读并同意以上领券规则
                    </div>
                    <div class="sub" :class="{ dc: !checked }" @click="sub">同意协议并领取</div>
                </div>
            </div>
            <div class="fb"></div>
        </template>
        <div v-else-if="!hasData" class="nodata">
            <img alt class="noimg"
                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/empty.png" />
            <div class="notext">无可领取优惠券</div>
        </div>
        <!-- <van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop-rule">
            <div class="pop-rule-title">
                xx协议
                <van-icon name="cross" class="pop-rule-close" color="#6C727A" @click="show = false" />
            </div>
            <div class="pop-rule-content">
                协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容
                协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容
                协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容
                协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容
                协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容协议内容
            </div>
            <div class="sub sub2" :class="{ dc: waitTime }" @click="receive">
                <template v-if="waitTime">请认真阅读以上内容 ({{ waitTime }})</template>
                <template v-else>同意协议并领取</template>
            </div>
        </van-popup> -->
    </div>
</template>
<script>
import { newAppBack, initBack, goMyVoucher } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import headerMixin from '@/mixin/headerMixin';
const activityNo = '2023spring';
export default {
    mixins: [userMixin, headerMixin],
    components: {
        headeBar,
    },
    data() {
        return {
            checked: false,
            show: false,
            waitTime: 6,
            hasData: true,
            success: false,
            remain: 0,
            voucherInfo: {}
        }
    },
    watch: {
        show(v) {
            if (v) {
                clearInterval(this.timer);
                this.waitTime = 6;
                this.timer = setInterval(() => {
                    this.waitTime--;
                    if (this.waitTime === 0) {
                        this.see = true;
                        clearInterval(this.timer);
                    }
                }, 1000)
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    mounted() {
        this.bindHeaderScroll(window, 200);
    },
    async created() {
        console.log(location.href);
        this.timer = null;
        this.canclick = true;
        this.see = false;
        initBack();
        await this.$getUserId();
        this.getVoucher();
    },
    methods: {
        newAppBack,
        sub() {
            if (!this.checked) {
                this.$toast('请先勾选');
                return;
            }
            // if (!this.see) {
            //     this.show = true;
            //     return;
            // }
            this.receive();
        },
        getVoucher() {
            this.$axios.post(this.baseURLApp + '/app/teacherOwnUser/h5/getUserAgreement', { token: this.token, userId: this.userId }).then((res) => {
                this.voucherInfo = res.data;
                this.hasData = res.data.teacherOwnUserId ? true : false;
            }).catch(()=>{
                this.hasData = false;
            })
        },
        receive() {
            // if (!this.canclick) return;
            // this.canclick = false;
            // setTimeout(() => {
            //     this.canclick = true;
            // }, 2000)
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: "spinner",
            });
            this.show = false;
            const ps = { token: this.token, userId: this.userId, teacherOwnUserId: this.voucherInfo.teacherOwnUserId };
            this.$axios.post(this.baseURLApp + '/app/teacherOwnUser/h5/signing', ps).then((res) => {
                this.success = true;
                this.remain = res.data.remainingCopies;
            }).catch().then(()=>{
                this.$toast.clear();
            })

        },
        next() {//继续领取
            if (this.remain) {
                this.getVoucher();
                this.success = false;
            } else {
                goMyVoucher();
            }
        }
    }
}
</script>
<style lang="less" scoped>
.coach-own-member-coupon {
    background: #F5F5F5;
    height: 100vh;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .coupon-body {
        padding: 52px 32px 290px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        position: relative;
        top: -124px;
        background: #fff;

        .coupon-name {
            padding: 0 18px;
            font-weight: bold;
            color: #BE5520;
            font-size: 36px;
            text-align: center;
            margin-bottom: 48px;
        }

        .coupon-rule {
            background: #FFFAF8;
            border-radius: 16px;
            padding: 40px;
            color: #5A0A00;
            font-size: 26px;
            line-height: 1.5;
            white-space: pre-line;
        }

    }

    .agree {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 226px;
        background: #fff;
        box-sizing: content-box;
        padding: 0 32px 0;

        .l1 {
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #707072;
            margin: 24px 0;

            .ck {
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }

            .agreement {
                color: #BE5520;
            }
        }
    }

    .sub {
        line-height: 96px;
        border-radius: 8px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #5A0A00;
        background: linear-gradient(270deg, #F3D29E 0%, #FBECD3 100%);
        margin: 28px 0;
    }

    .dc {
        opacity: 0.35;
    }

    .pop-rule {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .pop-rule-title {
            height: 132px;
            color: #242831;
            font-weight: bold;
            background: #fff;
            font-size: 32px;
            text-align: center;
            line-height: 130px;
            position: relative;

            .pop-rule-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                font-size: 28px;
            }
        }

        .pop-rule-content {
            color: #3C454E;
            border-bottom: 1px solid #eee;
            font-size: 26px;
            height: 800px;
            overflow: auto;
            line-height: 1.7;
            padding: 0 32px;
        }

        .sub2 {
            margin-left: 32px;
            margin-right: 32px;
        }
    }

    .success-box {
        text-align: center;
        padding-bottom: 280px;
        padding-top: 146px;

        .s1 {
            font-weight: bold;
            font-size: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            color: #242831;
            margin-bottom: 30px;

            .suc-logo {
                width: 64px;
                height: 64px;
                margin-right: 16px;
            }
        }

        .s2 {
            color: #3C454E;
            font-size: 24px;
            margin-bottom: 64px;
        }

        .s4 {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: content-box;
            background: #fff;
            z-index: 2;

            .remain {
                font-size: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 24px;

                .v-logo {
                    width: 88px;
                    height: 88px;
                    margin-right: 16px;
                }

                .remain-num {
                    color: #FF6E00;
                }
            }
        }

        .get {
            padding: 32px;
            border-top: 1px solid #eee;

            .sub {
                margin: 0;
            }
        }
    }

    .fb {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50vh;
        background: #fff;
        z-index: 1;
    }
    .zv{
        position: relative;
        z-index: 2;
    }

    .nodata {
        margin: 0 auto;
        width: 320px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .noimg {
            width: 320px;
            height: 320px;
        }

        .notext {
            font-size: 26px;
            color: #6C727A;
            margin-top: 20px;
        }
    }
}</style>
